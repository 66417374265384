<template>
  <div
    class="block-paragraph"
    v-html="computedData"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import MarkdownIt from 'markdown-it';

interface Props {
  data: string
}

const props = defineProps<Props>();
const markdownInstance = new MarkdownIt({
  html: false,
  breaks: true,
});

const computedData = computed(() => {
  return markdownInstance.render(props.data);
});
</script>

<style scoped>
.block-paragraph {
  @apply
    prose
    prose-ul:list-disc prose-ul:ml-5 prose-ul:mb-4 prose-ul:text-base
    prose-ol:list-decimal prose-ol:ml-5 prose-ol:mb-4 prose-ol:text-base
    prose-p:mb-4 prose-p:text-base
    prose-em:text-brand-black/60
    prose-strong:text-base
    prose-headings:font-bold prose-headings:text-balance prose-headings:w-fit
    prose-h1:md:text-6xl sm:text-5xl prose-h1:text-4xl
    prose-h2:sm:text-5xl prose-h2:text-3xl prose-h2:md:my-10 prose-h2:my-6
    prose-h3:sm:text-4xl prose-h3:text-2xl prose-h3:md:my-10 prose-h3:my-6
    prose-h4:sm:text-2xl prose-h4:text-xl prose-h4:md:my-10 prose-h4:my-6
    prose-h5:sm:text-xl prose-h5:text-lg prose-h5:md:my-10 prose-h5:my-6
    prose-a:underline prose-a:font-semibold
  ;
}
</style>
